<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-md-12 d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title" v-if="this.id == ''">Add New Client</h4>
                                <h4 class="card-title" v-else>Update Client</h4>
                            </div>
                            <div class="card-body new-card-body">
                                <form class="forms-sample" @submit.stop.prevent="onSubmit">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <b-form-group label="Client ID*" label-for="client_id">
                                                <b-form-input type="text" id="client_id" name="client_id" readonly placeholder="Client ID" v-model="client_id">
                                                </b-form-input>
                                            </b-form-group>
                                            <b-form-group label="Name*" label-for="name">
                                                <b-form-input type="text" id="name" name="name" placeholder="Client Name" v-model="$v.name.$model" :state="$v.name.$dirty ? !$v.name.$error : null" aria-describedby="input-1-live-feedback">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="$v.name.$error" id="input-1-live-feedback">{{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <b-form-group label="City" label-for="city">
                                                <b-form-input type="text" id="city" placeholder="City" v-model="city"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <b-form-group label="URL*" label-for="url">
                                                <b-form-input type="url" id="url" placeholder="https://client-name.watchspotter.app" v-model="$v.url.$model" readonly :state="$v.url.$dirty ? !$v.url.$error : null" aria-describedby="input-1-live-feedback"></b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.url.required" id="input-1-live-feedback">{{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="!$v.url.url" id="input-1-live-feedback">
                                                    Invalid Url.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <b-form-group label="Nickname" label-for="city">
                                                <b-form-input type="text" id="Nickname" placeholder="Nickname" v-model="$v.nick_name.$model" :state="$v.nick_name.$dirty ? !$v.nick_name.$error : null" aria-describedby="input-1-live-feedback"></b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.nick_name.required" id="input-1-live-feedback">{{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <b-form-group label="Version" label-for="Version">
                                                <b-form-select :options="versions" value-field="id" text-field="version_name"
                                                    v-model="$v.version_id.$model"
                                                    :state="$v.version_id.$dirty ? !$v.version_id.$error : null"
                                                    aria-describedby="input-1-live-feedback">
                                                    <b-form-select-option v-if="versions.length === 0" :disabled = true>{{ $t('no-version-found') }}</b-form-select-option></b-form-select>
                                                    <b-form-invalid-feedback v-if="!$v.version_id.required" id="input-1-live-feedback">{{ $t("field-required") }}
                                                    </b-form-invalid-feedback>
                                                    <!-- <b-form-invalid-feedback v-if="!$v.version_id.required" id="input-1-live-feedback">
                                                       {{ $t("field-required") }}
                                                    </b-form-invalid-feedback> -->
                                            </b-form-group>
                                            <!-- <b-form-group label="versions" label-for="versions">
                                                <v-select v-model="version_id" :options="versions" value-field="id" text-field="version_name"></v-select>
                                            </b-form-group> -->
                                        </div>
                                    </div>
                                    <div class="row" v-if="showToggleButton">
                                        <div class="col-lg-2 col-md-2">
                                            <label class="mr-sm-2 ml-0 pl-0" for="inline-form-custom-select-pref">{{ $t('WatchSpotter Pro Sync') }}</label>
                                        </div>
                                        <div class="col-lg-2 col-md-2">
                                            <toggle-button v-model="is_pro_sync" :sync="true" class="switchColor" />
                                        </div>
                                    </div>
                                    <b-button type="submit" variant="success" class="mr-2">
                                        <span v-if="id === ''">Submit</span>
                                        <span v-else>Update</span>
                                    </b-button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="row">
    
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Clients</h4>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" @on-search="searchFn" :search-options="{enabled: true,searchFn: searchFn}"
                            :isLoading.sync="isLoading" :pagination-options="{
                                        enabled: true,
                                        dropdownAllowAll: false,
                                        perPageDropdown: [10, 20, 50, 100, 200],
                                    }" :rows="rows" :columns="columns">
                            <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field === 'action'" class="text-nowrap">
                                            <b-button size="sm" @click="editclient(props.row.id)" class="mr-1 btn-info text-white">
                                                Edit
                                            </b-button>
                                            <b-button size="sm" @click="deleteclient(props.row.id)"
                                                        class="mr-1 btn-danger text-white">
                                                Delete
                                            </b-button>
                                        </span>
</template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
    import Vue from "vue";
    import API from '@/api'
    import Snotify, {
        SnotifyPosition
    } from 'vue-snotify'
    import {
        validationMixin
    } from "vuelidate";
    import {
        helpers,
        required,
        minLength,
        email,
        between,
        sameAs,
        requiredIf,
        url
    } from "vuelidate/lib/validators";
    
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Clients',
        mixins: [validationMixin],
        data() {
            return {
                name: "",
                nick_name: "",
                url: "",
                client_id: "",
                version_id: 1,
                city: "",
                id: "",
                is_pro_sync: false,
                serverParams: {
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [{
                        field: 'id', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }],
    
                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [{
                        label: 'Client Id',
                        field: 'client_id',
                        filterable: true
                    },
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Nickname',
                        field: 'nick_name',
                        filterable: true
                    },
                    {
                        label: 'Url',
                        field: 'url',
                        filterable: true
                    },
                    {
                        label: 'City',
                        field: 'city',
                        filterable: true
                    },
                    {
                        label: 'Version',
                        field: 'version.version_code',
                        filterable: true
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                versions: [],
                totalRecords: 0,
            };
        },
        validations: {
            name: {
                required,
            },
            nick_name: {
                required,
            },
            url: {
                url,
                required,
            },
            version_id: {
                required,
            },
        },
        methods: {
            getVersions() {
                API.getVersions(
                    data => {
                        this.versions = data.data;
                    },
                    err => {}
                )
            },
            loadItems() {
                API.getcleints(this.serverParams,
                    data => {
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        this.client_id = data.client_id;
                        this.url = window.location.origin + '/project/' + this.client_id;
                    },
                    err => {}
                )
            },
            deleteclient(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        let data = {
                            id: e
                        }
                        API.deleteclient(
                            data,
                            data => {
                                this.loadItems();
                                this.$swal(
                                    'Deleted!',
                                    'Record has been deleted.',
                                    'success'
                                )
                            },
                            err => {}
                        )
    
                    }
                })
            },
            onSubmit() {
                this.$v.name.$touch();
                this.$v.nick_name.$touch();
                this.$v.version_id.$touch();
                if (this.$v.name.$anyError || this.$v.nick_name.$anyError || this.$v.version_id.$anyError) {
                    return;
                }
                if (this.name != '') {
                    let data = {
                        name: this.name,
                        client_id: this.client_id,
                        nick_name: this.nick_name,
                        city: this.city,
                        id: this.id,
                        url: this.url,
                        version_id: this.version_id,
                        is_pro_sync: this.is_pro_sync
                    }
                    API.add_edit_client(
                        data,
                        data => {
                        console.log('data :', data);
                            if (data.status == 200) {
                                this.$snotify.success(data.message);
                                this.clear_form();
                                this.loadItems();
                            } else {
                                this.$snotify.error(data.message);
                            }
    
                        },
                        err => {
                            if(err.status == 400) {
                                console.log(err);
                                console.log('err.status :', err.status);
                                this.$snotify.error(err.errors.message.toString());
                            }
                        }
                    )
                } else {
                    this.$snotify.warning('Please fill required fields', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            },
            editclient(e) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                let data = {
                    id: e
                }
                API.get_client(
                    data,
                    data => {
                        this.name = data.name;
                        this.client_id = data.client_id;
                        this.nick_name = data.nick_name;
                        this.city = data.city;
                        this.url = data.url;
                        this.id = data.id;
                        this.version_id = data.version_id;
                        this.is_pro_sync = data.is_pro_sync;
                    },
                    err => {})
            },
            clear_form() {
                this.name = "";
                this.city = "";
                this.url = "";
                this.nick_name = "";
                this.id = "";
                this.version_id = 1;
                this.$v.$reset();
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
    
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
    
            onPerPageChange(params) {
                this.updateParams({
                    perPage: params.currentPerPage
                });
                this.loadItems();
            },
    
            onSortChange(params) {
                console.log(params.sortType);
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({
                    searchTerm: params
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            getVersionNameById(id) {
                const version = this.versions.find(version => version.id === id);
                return version ? version.version_name : '';
            },
        },
        computed: {
            showToggleButton() {
                return this.version_id === 2 || this.getVersionNameById(this.version_id) === "WatchSpotter Pro";
            }
        },
        mounted() {
            this.loadItems();
            this.getVersions();
        }
    }
</script>

<style>
    .row .card .new-card-body {
        padding: 1.25rem 1.437rem !important;
    }
    @media (max-width: 450px) {
        .vgt-wrap__footer .footer__navigation__page-btn:nth-child(3) {
            float: right;
            margin-left: 8px;
        }
        .vgt-wrap__footer .footer__navigation__page-btn:nth-child(2) {
            margin-left: 0px;
            float: left;
        }
        .vgt-wrap__footer .footer__navigation__page-info {
            width: 100%;
            text-align: center;
            margin: 0;
        }
        .vgt-pull-right {
            float: inherit !important;
        }
    }
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }

</style>
